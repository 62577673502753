import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, VStack, Text, useToast, Button } from '@chakra-ui/react';
import { MdError, MdWarning, MdCheckCircle, MdInfoOutline } from 'react-icons/md';
import { fetchTaskDetails, downloadTranscription } from '../../api';
import styles from './TaskDetails.module.scss';
import DynamicButton from '../shared/DynamicButton';
import LoadingOverlay from '../shared/LoadingOverlay';
import ErrorBoundary from '../shared/ErrorBoundary';
import DiscrepanciesModal from '../shared/DiscrepanciesModal';

interface Discrepancy {
  descripcion: string;
  tipo: string;
}

interface ReviewInfo {
  has_hallucinations: boolean;
  discrepancies: Discrepancy[];
  precision_status: string;
}

interface TaskDetails {
  id: string;
  name: string;
  status: string;
  created_at: string;
  updated_at: string;
  files: {
    transcription_file?: string;
    article_files?: string[];
  };
  articles_generated: boolean;
  snippets_generated: boolean;
  is_auto_created: boolean;
  steps: {
    [key: string]: {
      status: string;
      completed_at: string | null;
    };
  };
  audio_source?: string;
  review_info?: ReviewInfo;
}

interface TaskDetailsProps {
  taskId: string;
  taskStatus: string;
  onViewArticles: () => void;
  onViewSnippets: () => void;
  onTaskUpdated: () => void;
}

const TaskDetails: React.FC<TaskDetailsProps> = React.memo(({
  taskId,
  taskStatus,
  onViewArticles,
  onViewSnippets,
  onTaskUpdated
}) => {
  const [taskDetails, setTaskDetails] = useState<TaskDetails | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toast = useToast();

  const loadTaskDetails = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const details = await fetchTaskDetails(taskId);
      setTaskDetails(details);
    } catch (error) {
      console.error('Error fetching task details:', error);
      setError('No se pudieron cargar los detalles de la tarea. Por favor, intente de nuevo.');
      toast({
        title: 'Error',
        description: 'No se pudieron cargar los detalles de la tarea. Por favor, intente de nuevo.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginBottom: '70px',
        },
      });
    } finally {
      setIsLoading(false);
    }
  }, [taskId, toast]);

  useEffect(() => {
    loadTaskDetails();
  }, [loadTaskDetails]);

  useEffect(() => {
    if (taskDetails && taskDetails.status !== taskStatus) {
      loadTaskDetails();
      onTaskUpdated();
    }
  }, [taskStatus, taskDetails, loadTaskDetails, onTaskUpdated]);

  // Reset modal state when taskId changes
  useEffect(() => {
    setIsModalOpen(false);
  }, [taskId]);

  const handleDownloadTranscription = useCallback(async () => {
    try {
      await downloadTranscription(taskId);
    } catch (error) {
      console.error('Error downloading transcription:', error);
      toast({
        title: 'Error',
        description: 'No se pudo descargar la transcripción. Por favor, intente de nuevo.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginBottom: '70px',
        },
      });
    }
  }, [taskId, toast]);

  const formatDate = useCallback((dateString: string) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
      return `Hoy a las ${date.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' })}`;
    } else if (date.toDateString() === yesterday.toDateString()) {
      return `Ayer a las ${date.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' })}`;
    } else {
      return date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' });
    }
  }, []);

  const getStatusText = useCallback((status: string) => {
    switch (status.toLowerCase()) {
      case 'completed':
        return 'Completado';
      case 'pending':
        return 'Pendiente';
      case 'reprocessing':
        return 'Reprocesando';
      case 'pending_reprocessing':
        return 'Pendiente de reprocesamiento';
      case 'error':
        return 'Error';
      default:
        return status;
    }
  }, []);

  const addPeriodIfNeeded = useCallback((text: string) => {
    return text.endsWith('.') ? text : `${text}.`;
  }, []);

  const hasDiscrepancies = (reviewInfo?: ReviewInfo): boolean => {
    if (!reviewInfo) return false;
    return reviewInfo.has_hallucinations || (Array.isArray(reviewInfo.discrepancies) && reviewInfo.discrepancies.length > 0);
  };

  const getReviewStatus = useCallback((reviewInfo?: ReviewInfo) => {
    if (!reviewInfo) return null;

    if (reviewInfo.has_hallucinations) {
      return {
        icon: <MdError size={16} color="#E53E3E" />,
        message: 'Se detectaron alucinaciones en el contenido generado.',
        color: 'red.500',
        hasIssues: true
      };
    }

    if (Array.isArray(reviewInfo.discrepancies) && reviewInfo.discrepancies.length > 0) {
      return {
        icon: <MdWarning size={16} color="#D69E2E" />,
        message: 'Se encontraron posibles discrepancias en el contenido.',
        color: 'yellow.500',
        hasIssues: true
      };
    }

    return {
      icon: <MdCheckCircle size={16} color="#38A169" />,
      message: 'No se encontraron discrepancias.',
      color: 'green.500',
      hasIssues: false
    };
  }, []);

  const memoizedContent = useMemo(() => {
    if (!taskDetails) return null;

    const reviewStatus = getReviewStatus(taskDetails.review_info);
    const showDiscrepanciesButton = hasDiscrepancies(taskDetails.review_info);

    return (
      <VStack align="stretch" spacing={4} className={styles.taskDetails}>
        <Text fontSize="xl" fontWeight="bold">
          {taskDetails.name}
        </Text>
        <Box className={styles.detailLine}>
          <Text>
            <strong>Estado:</strong> <span className={styles[taskDetails.status.toLowerCase()]}>{addPeriodIfNeeded(getStatusText(taskDetails.status))}</span>
          </Text>
        </Box>
        {reviewStatus && (
          <Box className={styles.reviewLine}>
            <Text>
              <strong>Revisión:</strong>
            </Text>
            {reviewStatus.icon}
            <Text color={reviewStatus.color}>{reviewStatus.message}</Text>
            {showDiscrepanciesButton && (
              <Button
                leftIcon={<MdInfoOutline size={14} />}
                height="24px"
                minH="24px"
                size="xs"
                variant="ghost"
                colorScheme={taskDetails.review_info?.has_hallucinations ? "red" : "yellow"}
                onClick={() => setIsModalOpen(true)}
                padding="0 8px"
                fontSize="12px"
              >
              </Button>
            )}
          </Box>
        )}
        <Box className={styles.detailLine}>
          <Text>
            <strong>Creado:</strong> {addPeriodIfNeeded(formatDate(taskDetails.created_at))}
          </Text>
        </Box>
        <Box className={styles.detailLine}>
          <Text>
            <strong>Actualizado:</strong> {addPeriodIfNeeded(formatDate(taskDetails.updated_at))}
          </Text>
        </Box>
        {taskDetails.is_auto_created && (
          <Box className={styles.detailLine}>
            <Text>
              <strong>Fuente:</strong> Procesado automáticamente a partir de RSS.
            </Text>
          </Box>
        )}
        {taskDetails.audio_source && (
          <Box className={styles.audioPlayerContainer}>
            <audio controls className={styles.audioPlayer}>
              <source src={taskDetails.audio_source} type="audio/mpeg" />
              Tu navegador no soporta el elemento de audio.
            </audio>
          </Box>
        )}
        {taskDetails.articles_generated && !['reprocessing', 'pending_reprocessing'].includes(taskDetails.status.toLowerCase()) && (
          <DynamicButton onClick={onViewArticles}>
            Ver artículos generados
          </DynamicButton>
        )}
        {taskDetails.snippets_generated && !['reprocessing', 'pending_reprocessing'].includes(taskDetails.status.toLowerCase()) && (
          <DynamicButton onClick={onViewSnippets}>
            Ver extractos generados
          </DynamicButton>
        )}
        {taskDetails.files?.transcription_file && (
          <DynamicButton onClick={handleDownloadTranscription}>
            Descargar transcripción original
          </DynamicButton>
        )}

        {taskDetails.review_info && (
          <DiscrepanciesModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            reviewInfo={taskDetails.review_info}
          />
        )}
      </VStack>
    );
  }, [taskDetails, formatDate, getStatusText, addPeriodIfNeeded, onViewArticles, onViewSnippets, handleDownloadTranscription, getReviewStatus, isModalOpen]);

  if (isLoading) {
    return <LoadingOverlay message="Cargando detalles de la tarea..." />;
  }

  if (error) {
    return (
      <Box className={styles.taskDetailsContainer}>
        <Box className={styles.messageContainer}>
          <Text className={styles.errorMessage}>{error}</Text>
          <DynamicButton onClick={loadTaskDetails}>Reintentar</DynamicButton>
        </Box>
      </Box>
    );
  }

  if (!taskDetails) {
    return (
      <Box className={styles.taskDetailsContainer}>
        <Box className={styles.messageContainer}>
          <Text className={styles.infoMessage}>No se encontraron detalles para esta tarea.</Text>
        </Box>
      </Box>
    );
  }

  return (
    <ErrorBoundary>
      <Box className={styles.taskDetailsContainer}>
        {memoizedContent}
      </Box>
    </ErrorBoundary>
  );
});

export default TaskDetails;

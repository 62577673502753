import axios, { AxiosError } from 'axios';

const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:5000';

console.log('API URL:', API_URL);

// Create custom axios instance for auth checking
const authApi = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  validateStatus: (status) => {
    return (status >= 200 && status < 300) || status === 401;
  }
});

// Regular API instance for other requests
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

api.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response && error.response.status !== 401) {
      console.error('Response error:', error.response ? error.response.data : error.message);
    }
    return Promise.reject(error);
  }
);

const handleApiError = (error: unknown, defaultMessage: string): Error => {
  if (axios.isAxiosError(error)) {
    const message = error.response?.data?.message || error.message || defaultMessage;
    return new Error(message);
  }
  return new Error(defaultMessage);
};

export const getAudioUrl = (audioPath: string): string => {
  try {
    if (audioPath.startsWith('http://') || audioPath.startsWith('https://')) {
      return audioPath;
    }

    if (!audioPath.startsWith('/')) {
      audioPath = `/${audioPath}`;
    }

    return `${API_URL}${audioPath}`;
  } catch (error) {
    console.error('Error constructing audio URL:', error);
    return `${API_URL}/audio/not-found`;
  }
};

export const downloadTranscription = async (taskId: string): Promise<void> => {
  try {
    window.open(`${api.defaults.baseURL}/tasks/${taskId}/transcription`);
  } catch (error) {
    throw handleApiError(error, 'Error al descargar la transcripción');
  }
};

export const login = async (username: string, password: string): Promise<void> => {
  try {
    const response = await api.post('/auth/login', { username, password });
    return response.data;
  } catch (error) {
    throw handleApiError(error, 'Error de inicio de sesión');
  }
};

export const logout = async (): Promise<void> => {
  try {
    await api.post('/auth/logout');
  } catch (error) {
    throw handleApiError(error, 'Error al cerrar sesión');
  }
};

export const checkAuthStatus = async (): Promise<boolean> => {
  try {
    const response = await authApi.get('/auth/check-auth');
    return response.data.isAuthenticated === true;
  } catch (error) {
    // This will only trigger for network errors or other unexpected issues
    console.error('Unexpected error checking auth status:', error);
    return false;
  }
};

export const regenerateArticle = async (taskId: string, customInstructions: string): Promise<void> => {
  const formData = new FormData();
  // Asegurar que no se envíe undefined o null
  formData.append('custom_instructions', customInstructions || '');

  try {
    const response = await api.post(`/tasks/${taskId}/regenerate_article`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (response.status !== 201) {
      throw new Error('Regeneration failed');
    }
  } catch (error) {
    throw handleApiError(error, 'Error al regenerar el artículo');
  }
};

export const createTask = async (
  taskName: string,
  audioFile: File,
  customInstructions: string,
  programType: string = '',
  onProgress?: (progress: number) => void,
  autoPublish: boolean = false
): Promise<string> => {
  const formData = new FormData();
  formData.append('task_name', taskName);
  formData.append('file', audioFile);
  formData.append('custom_instructions', customInstructions);
  if (programType) {
    formData.append('program_type', programType);
  }
  formData.append('auto_publish', String(autoPublish));

  try {
    const response = await api.post('/tasks', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          onProgress?.(percentCompleted);
        }
      },
    });

    if (response.status !== 201) {
      throw new Error('Task creation failed');
    }

    return response.data.task_id;
  } catch (error) {
    throw handleApiError(error, 'Error al crear la tarea');
  }
};

export const fetchTasks = async (): Promise<any[]> => {
  try {
    const response = await api.get('/tasks');
    return response.data;
  } catch (error) {
    throw handleApiError(error, 'Error al obtener las tareas');
  }
};

export const fetchTaskDetails = async (taskId: string): Promise<any> => {
  try {
    const response = await api.get(`/tasks/${taskId}`);
    return response.data;
  } catch (error) {
    throw handleApiError(error, 'Error al obtener los detalles de la tarea');
  }
};

export const fetchTaskArticles = async (taskId: string): Promise<any> => {
  try {
    const response = await api.get(`/tasks/${taskId}/articles`);
    return response.data;
  } catch (error) {
    throw handleApiError(error, 'Error al obtener los artículos de la tarea');
  }
};

export const updateTaskArticles = async (taskId: string, articles: any): Promise<void> => {
  try {
    await api.put(`/tasks/${taskId}/articles`, articles);
  } catch (error) {
    throw handleApiError(error, 'Error al actualizar los artículos de la tarea');
  }
};

export const restoreOriginalArticles = async (taskId: string): Promise<any> => {
  try {
    const response = await api.post(`/tasks/${taskId}/articles/restore`);
    return response.data.articles;
  } catch (error) {
    throw handleApiError(error, 'Error al restaurar los artículos originales');
  }
};

export const fetchTaskSnippets = async (taskId: string): Promise<any> => {
  try {
    const response = await api.get(`/tasks/${taskId}/snippets`);
    return response.data;
  } catch (error) {
    throw handleApiError(error, 'Error al obtener los extractos de la tarea');
  }
};

export const fetchGenericArticle = async (taskId: string): Promise<{
  content: string;
  title: string;
  created_at: string;
  updated_at: string;
}> => {
  try {
    const response = await api.get(`/tasks/${taskId}/generic-article`);
    return response.data;
  } catch (error) {
    throw handleApiError(error, 'Error al obtener el artículo genérico');
  }
};

export default api;

import React, { useMemo } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  HStack,
  VStack,
  UnorderedList,
  ListItem,
  Divider,
} from '@chakra-ui/react';
import { MdError, MdWarning } from 'react-icons/md';

interface Discrepancy {
  descripcion: string;
  tipo: string;
}

interface ReviewInfo {
  has_hallucinations: boolean;
  discrepancies: Discrepancy[];
  precision_status: string;
}

interface DiscrepanciesModalProps {
  isOpen: boolean;
  onClose: () => void;
  reviewInfo: ReviewInfo;
}

interface GroupedDiscrepancies {
  [key: string]: string[];
}

const typeLabels: { [key: string]: string } = {
  'alucinacion': 'Alucinaciones',
  'omision': 'Omisiones',
  'formato_incorrecto': 'Formato Incorrecto',
  'problema_cita_titulo': 'Problema en Cita del Título',
  'inconsistencia': 'Inconsistencias',
  'imprecision': 'Imprecisiones',
};

const DiscrepanciesModal: React.FC<DiscrepanciesModalProps> = ({
  isOpen,
  onClose,
  reviewInfo,
}) => {
  const groupedDiscrepancies = useMemo(() => {
    const grouped: GroupedDiscrepancies = {};

    reviewInfo.discrepancies.forEach((discrepancy) => {
      if (!grouped[discrepancy.tipo]) {
        grouped[discrepancy.tipo] = [];
      }
      grouped[discrepancy.tipo].push(discrepancy.descripcion);
    });

    return grouped;
  }, [reviewInfo.discrepancies]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack>
            {reviewInfo.has_hallucinations ? (
              <MdError size={24} color="#E53E3E" />
            ) : (
              <MdWarning size={24} color="#D69E2E" />
            )}
            <Text>
              {reviewInfo.has_hallucinations
                ? 'Alucinaciones Detectadas'
                : 'Discrepancias Encontradas'}
            </Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          {reviewInfo.has_hallucinations && (
            <>
              <Text mb={4} color="red.500" fontWeight="medium">
                Se han detectado posibles alucinaciones en el contenido generado. Esto significa que el sistema posiblemente ha incluido información que no está presente en el audio original.
              </Text>
              <Divider mb={4} />
            </>
          )}

          <VStack align="stretch" spacing={4}>
            {Object.entries(groupedDiscrepancies).map(([tipo, descripciones]) => (
              <VStack key={tipo} align="stretch" spacing={2}>
                <Text fontWeight="bold" color={tipo === 'alucinacion' ? 'red.500' : 'yellow.600'}>
                  {typeLabels[tipo] || tipo}:
                </Text>
                <UnorderedList spacing={2} styleType="none" ml={4}>
                  {descripciones.map((descripcion, idx) => (
                    <ListItem
                      key={idx}
                      position="relative"
                      pl={4}
                      _before={{
                        content: '""',
                        position: 'absolute',
                        left: 0,
                        top: '8px',
                        width: '6px',
                        height: '6px',
                        borderRadius: '50%',
                        backgroundColor: tipo === 'alucinacion' ? 'red.500' : 'yellow.500'
                      }}
                    >
                      {descripcion}
                    </ListItem>
                  ))}
                </UnorderedList>
              </VStack>
            ))}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DiscrepanciesModal;

import React, { useState, useEffect, useCallback, useRef, useMemo, lazy, Suspense } from 'react';
import { Box, Spinner, Text, useToast } from '@chakra-ui/react';
import { ErrorBoundary } from 'react-error-boundary';
import TaskCreationForm from '../components/features/TaskCreationForm';
import TaskList from '../components/features/TaskList';
import TaskDetails from '../components/features/TaskDetails';
import styles from './DashboardPage.module.scss';
import { fetchTasks } from '../api';
import LoadingOverlay from '../components/shared/LoadingOverlay';
import ErrorFallback from '../components/shared/ErrorFallback';

const ArticleViewer = lazy(() => import('../components/features/ArticleViewer'));
const SnippetsView = lazy(() => import('../components/features/SnippetsView'));

const POLLING_INTERVAL = 10000; // 10 seconds

interface Task {
  id: string;
  name: string;
  status: string;
  created_at: string;
  updated_at: string;
  articles_generated: boolean;
  snippets_generated: boolean;
}

const DashboardPage: React.FC = () => {
  const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null);
  const [showTaskCreation, setShowTaskCreation] = useState(false);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [showArticles, setShowArticles] = useState(false);
  const [showSnippets, setShowSnippets] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const lastFetchTimeRef = useRef<number>(0);
  const isMountedRef = useRef(true);
  const toast = useToast();

  const loadTasks = useCallback(async (forceRefresh: boolean = false) => {
    const now = Date.now();
    if (forceRefresh || now - lastFetchTimeRef.current >= POLLING_INTERVAL) {
      setIsLoading(true);
      setError(null);
      try {
        const fetchedTasks = await fetchTasks();
        if (isMountedRef.current) {
          setTasks((prevTasks) => {
            // Only update tasks if there are changes
            if (JSON.stringify(prevTasks) !== JSON.stringify(fetchedTasks)) {
              return fetchedTasks;
            }
            return prevTasks;
          });
          lastFetchTimeRef.current = now;
        }
      } catch (error) {
        console.error('Error fetching tasks:', error);
        setError('Error al cargar las tareas. Por favor, intente de nuevo.');
        toast({
          title: 'Error',
          description: 'Error al cargar las tareas. Por favor, intente de nuevo.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          containerStyle: {
            marginBottom: '70px',
          },
        });
      } finally {
        setIsLoading(false);
      }
    }
  }, [toast]);

  useEffect(() => {
    isMountedRef.current = true;
    loadTasks(true);
    const intervalId = setInterval(() => loadTasks(), POLLING_INTERVAL);

    return () => {
      isMountedRef.current = false;
      clearInterval(intervalId);
    };
  }, [loadTasks]);

  const handleTaskSelect = useCallback((taskId: string) => {
    setSelectedTaskId(taskId);
    setShowTaskCreation(false);
    setShowArticles(false);
    setShowSnippets(false);
  }, []);

  const handleNewAudioClick = useCallback(() => {
    setSelectedTaskId(null);
    setShowTaskCreation(true);
    setShowArticles(false);
    setShowSnippets(false);
  }, []);

  const handleViewArticles = useCallback(() => {
    setShowArticles(true);
    setShowSnippets(false);
  }, []);

  const handleViewSnippets = useCallback(() => {
    setShowSnippets(true);
    setShowArticles(false);
  }, []);

  const handleCloseArticles = useCallback(() => {
    setShowArticles(false);
  }, []);

  const handleCloseSnippets = useCallback(() => {
    setShowSnippets(false);
  }, []);

  const handleTaskCreated = useCallback((taskId: string, taskName: string) => {
    loadTasks(true);
    setSelectedTaskId(taskId);
    setShowTaskCreation(false);
    toast({
      title: 'Tarea Creada',
      description: `La tarea '${taskName}' ha sido creada exitosamente.`,
      status: 'success',
      duration: 5000,
      isClosable: true,
      containerStyle: {
        marginBottom: '70px',
      },
    });
  }, [loadTasks, toast]);

  const handleTaskUpdated = useCallback(() => {
    loadTasks(true);
  }, [loadTasks]);

  const selectedTask = useMemo(() => tasks.find(task => task.id === selectedTaskId), [tasks, selectedTaskId]);

  const renderContent = useCallback(() => {
    if (isLoading && tasks.length === 0) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <Spinner size="xl" />
        </Box>
      );
    }

    if (error) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <Text color="red.500">{error}</Text>
        </Box>
      );
    }

    if (showTaskCreation) {
      return <TaskCreationForm onTaskCreated={handleTaskCreated} />;
    }

    if (selectedTaskId && selectedTask) {
      if (showArticles) {
        return (
          <Suspense fallback={<LoadingOverlay message="Cargando visor de artículos..." />}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <ArticleViewer
                taskId={selectedTaskId}
                onClose={handleCloseArticles}
                onViewSnippets={handleViewSnippets}
              />
            </ErrorBoundary>
          </Suspense>
        );
      }
      if (showSnippets) {
        return (
          <Suspense fallback={<LoadingOverlay message="Cargando extractos..." />}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <SnippetsView
                taskId={selectedTaskId}
                onClose={handleCloseSnippets}
                onViewArticles={handleViewArticles}
              />
            </ErrorBoundary>
          </Suspense>
        );
      }
      return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <TaskDetails
            taskId={selectedTaskId}
            taskStatus={selectedTask.status}
            onViewArticles={handleViewArticles}
            onViewSnippets={handleViewSnippets}
            onTaskUpdated={handleTaskUpdated}
          />
        </ErrorBoundary>
      );
    }

    return (
      <Box className={styles.noTaskSelected}>
        Selecciona una tarea o crea una nueva para ver los detalles.
      </Box>
    );
  }, [isLoading, tasks.length, error, showTaskCreation, selectedTaskId, selectedTask, showArticles, showSnippets, handleTaskCreated, handleCloseArticles, handleCloseSnippets, handleViewArticles, handleViewSnippets, handleTaskUpdated]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Box className={styles.dashboardContainer}>
        <Box className={styles.taskListContainer}>
          <TaskList
            tasks={tasks}
            onTaskSelect={handleTaskSelect}
            selectedTaskId={selectedTaskId}
            onNewAudioClick={handleNewAudioClick}
            isTaskCreationActive={showTaskCreation}
            isLoading={isLoading}
          />
        </Box>
        <Box className={styles.taskDetailsWrapper}>
          <Box className={styles.taskDetailsContainer}>
            {renderContent()}
          </Box>
        </Box>
      </Box>
    </ErrorBoundary>
  );
};

export default React.memo(DashboardPage);

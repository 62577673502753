import React, { useEffect, useState } from 'react';
import { ChakraProvider, useToast } from '@chakra-ui/react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import createTheme from './theme';
import { createConfig } from './config/appConfig';
import { useConfigStore } from './store/appConfigStore';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import LoadingScreen from './components/shared/LoadingScreen';
import { checkAuthStatus } from './api';
import { useAuthStore } from './store/authStore';
import { Header } from './components/shared/Header';
import Footer from './components/shared/Footer';
import ErrorFallback from './components/shared/ErrorFallback';

const App: React.FC = () => {
  const config = createConfig();
  const theme = createTheme(config);
  const { setConfig } = useConfigStore();
  const { isAuthenticated, setIsAuthenticated } = useAuthStore();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const toast = useToast();

  useEffect(() => {
    setConfig(config);
    document.title = config.applicationName;

    const verifyAuthStatus = async () => {
      try {
        const authStatus = await checkAuthStatus();
        setIsAuthenticated(authStatus);
      } catch (error) {
        console.error('Error al verificar el estado de autenticación:', error);
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };

    verifyAuthStatus();
  }, [setConfig, setIsAuthenticated, config.applicationName, toast]);

  if (isLoading) {
    return <LoadingScreen loading="Cargando..." />;
  }

  const showHeaderFooter = location.pathname === '/dashboard';

  return (
    <ChakraProvider theme={theme}>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => window.location.reload()}
        onError={(error, info) => {
          console.error('Unhandled error:', error, info);
          toast({
            title: 'Error inesperado',
            description: 'Ha ocurrido un error inesperado. Por favor, recargue la página.',
            status: 'error',
            duration: null,
            isClosable: true,
            containerStyle: {
              marginBottom: '70px',
            },
          });
        }}
      >
        {showHeaderFooter && <Header />}
        <Routes>
          <Route
            path="/"
            element={isAuthenticated ? <Navigate to="/dashboard" replace /> : <HomePage />}
          />
          <Route
            path="/login"
            element={isAuthenticated ? <Navigate to="/dashboard" replace /> : <LoginPage />}
          />
          <Route
            path="/dashboard"
            element={
              isAuthenticated ? (
                <DashboardPage />
              ) : (
                <Navigate to="/login" state={{ from: location }} replace />
              )
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        {showHeaderFooter && <Footer />}
      </ErrorBoundary>
    </ChakraProvider>
  );
};

export default App;

import React, { useState, useRef, useCallback, useMemo } from 'react';
import { Box, VStack, FormControl, FormLabel, Input, Text, useToast, Progress, Center, FormErrorMessage, Textarea, Select, Switch } from '@chakra-ui/react';
import { FiUpload, FiFile } from 'react-icons/fi';
import { createTask } from '../../api';
import styles from './TaskCreationForm.module.scss';
import DynamicButton from '../shared/DynamicButton';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../shared/ErrorFallback';

interface TaskCreationFormProps {
  onTaskCreated: (taskId: string, taskName: string) => void;
}

const PROGRAM_TYPES = [
  { label: 'Genérico', value: '' },
  { label: 'Consultorio: Capital, La Bolsa y La Vida', value: 'capital_consultorio' },
  { label: 'Consultorio: Mercado Abierto', value: 'mercado_consultorio' },
] as const;

const TaskCreationForm: React.FC<TaskCreationFormProps> = React.memo(({ onTaskCreated }) => {
  const [taskName, setTaskName] = useState('');
  const [programType, setProgramType] = useState('');
  const [audioFile, setAudioFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [formErrors, setFormErrors] = useState<{
    taskName?: string;
    audioFile?: string;
    customInstructions?: string;
    programType?: string;
  }>({});
  const [customInstructions, setCustomInstructions] = useState('');
  const [autoPublish, setAutoPublish] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const toast = useToast();

  const MAX_CUSTOM_INSTRUCTIONS_LENGTH = 500;

  const validAudioFormats = useMemo(() => [
    'audio/mpeg', 'audio/mp3', 'audio/wav', 'audio/ogg', 'audio/aac', 'audio/flac'
  ], []);

  const handleTaskNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setTaskName(e.target.value);
    setFormErrors(prev => ({ ...prev, taskName: undefined }));
  }, []);

  const handleProgramTypeChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setProgramType(e.target.value);
    setFormErrors(prev => ({ ...prev, programType: undefined }));
  }, []);

  const handleCustomInstructionsChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    if (value.length <= MAX_CUSTOM_INSTRUCTIONS_LENGTH) {
      setCustomInstructions(value);
      setFormErrors(prev => ({ ...prev, customInstructions: undefined }));
    }
  }, []);

  const validateAndSetFile = useCallback((file: File | undefined) => {
    if (file && validAudioFormats.includes(file.type)) {
      setAudioFile(file);
      setFormErrors(prev => ({ ...prev, audioFile: undefined }));
    } else {
      setFormErrors(prev => ({ ...prev, audioFile: 'Por favor, selecciona un archivo de audio válido (MP3, WAV, OGG, AAC, FLAC).' }));
    }
  }, [validAudioFormats]);

  const handleFileSelect = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    validateAndSetFile(file);
  }, [validateAndSetFile]);

  const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  }, []);

  const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    validateAndSetFile(file);
  }, [validateAndSetFile]);

  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    const errors: { taskName?: string; audioFile?: string; customInstructions?: string } = {};
    if (!taskName.trim()) {
      errors.taskName = 'Por favor, proporciona un nombre de tarea.';
    }
    if (!audioFile) {
      errors.audioFile = 'Por favor, selecciona un archivo de audio.';
    }
    if (customInstructions.length > MAX_CUSTOM_INSTRUCTIONS_LENGTH) {
      errors.customInstructions = `Las instrucciones personalizadas no deben exceder ${MAX_CUSTOM_INSTRUCTIONS_LENGTH} caracteres.`;
    }
    setFormErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    setIsSubmitting(true);
    try {
      const taskId = await createTask(
        taskName,
        audioFile!,
        customInstructions,
        programType,
        (progress) => {
          setUploadProgress(progress);
        },
        autoPublish
      );
      onTaskCreated(taskId, taskName);
      setTaskName('');
      setProgramType('');
      setAudioFile(null);
      setCustomInstructions('');
      setUploadProgress(0);
      setAutoPublish(false);
    } catch (error) {
      console.error('Error creating task:', error);
      toast({
        title: 'Error',
        description: 'Error al crear la tarea. Por favor, intenta de nuevo.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        containerStyle: {
          marginBottom: '70px',
        },
      });
    } finally {
      setIsSubmitting(false);
    }
  }, [taskName, programType, audioFile, customInstructions, autoPublish, toast, onTaskCreated]);

  const dropzoneContent = useMemo(() => {
    if (isSubmitting) {
      return (
        <Center flexDirection="column" height="100%">
          <Text fontSize="lg" fontWeight="bold" mb={4}>
            Subiendo... {uploadProgress}%
          </Text>
          <Progress
            value={uploadProgress}
            size="lg"
            width="80%"
            colorScheme="blue"
            borderRadius="md"
          />
        </Center>
      );
    }
    if (audioFile) {
      return (
        <>
          <FiFile size={48} className={styles.fileIcon} />
          <Text mt={2} fontWeight="bold">{audioFile.name}</Text>
          <Text mt={1}>Haz clic o arrastra un nuevo archivo para cambiar</Text>
        </>
      );
    }
    return (
      <>
        <FiUpload size={48} />
        <Text mt={2}>Arrastra y suelta un archivo de audio aquí o haz clic para seleccionar</Text>
      </>
    );
  }, [isSubmitting, uploadProgress, audioFile]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Box className={styles.taskCreationForm}>
        <form onSubmit={handleSubmit}>
          <VStack spacing={4} align="stretch">
            <div className={styles.headerGroup}>
              <FormControl isInvalid={!!formErrors.taskName}>
                <FormLabel htmlFor="taskName">Nombre de la Tarea</FormLabel>
                <Input
                  id="taskName"
                  type="text"
                  value={taskName}
                  onChange={handleTaskNameChange}
                  placeholder="Ingresa el nombre de la tarea"
                  disabled={isSubmitting}
                  maxLength={100}
                />
                <FormErrorMessage>{formErrors.taskName}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!formErrors.programType}>
                <FormLabel htmlFor="programType">Tipo de Programa</FormLabel>
                <Select
                  id="programType"
                  value={programType}
                  onChange={handleProgramTypeChange}
                  disabled={isSubmitting}
                >
                  {PROGRAM_TYPES.map(({ label, value }) => (
                    <option key={value} value={value}>{label}</option>
                  ))}
                </Select>
                <FormErrorMessage>{formErrors.programType}</FormErrorMessage>
              </FormControl>
            </div>

            <FormControl isInvalid={!!formErrors.audioFile}>
              <FormLabel htmlFor="audioFile">Archivo de Audio</FormLabel>
              <Box
                className={`${styles.dropZone} ${audioFile ? styles.fileSelected : ''} ${isSubmitting ? styles.uploading : ''}`}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onClick={() => fileInputRef.current?.click()}
              >
                {dropzoneContent}
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileSelect}
                  accept="audio/*"
                  style={{ display: 'none' }}
                  disabled={isSubmitting}
                />
              </Box>
              <FormErrorMessage>{formErrors.audioFile}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!formErrors.customInstructions}>
              <FormLabel htmlFor="customInstructions">Instrucciones personalizadas</FormLabel>
              <Textarea
                id="customInstructions"
                value={customInstructions}
                onChange={handleCustomInstructionsChange}
                placeholder="Puede personalizar el resultado de la generación de artículos ingresando instrucciones aquí."
                disabled={isSubmitting}
                maxLength={MAX_CUSTOM_INSTRUCTIONS_LENGTH}
                resize="vertical"
              />
              <Text fontSize="sm" color="gray.500">
                {customInstructions.length}/{MAX_CUSTOM_INSTRUCTIONS_LENGTH}
              </Text>
              <FormErrorMessage>{formErrors.customInstructions}</FormErrorMessage>
            </FormControl>

            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="auto-publish" mb="0">
                Publicación automática
              </FormLabel>
              <Switch
                id="auto-publish"
                isChecked={autoPublish}
                onChange={(e) => setAutoPublish(e.target.checked)}
                disabled={isSubmitting}
              />
            </FormControl>

            <DynamicButton
              type="submit"
              isDisabled={!taskName || !audioFile || isSubmitting}
              isLoading={isSubmitting}
              loadingText="Creando Tarea..."
            >
              Crear Tarea
            </DynamicButton>
          </VStack>
        </form>
      </Box>
    </ErrorBoundary>
  );
});

export default TaskCreationForm;
